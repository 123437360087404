import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://fc11a5af08baa2d4b4ff4d0526e16237@o4507067746025472.ingest.us.sentry.io/4507067751006208",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});